import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _, { isNull } from 'lodash';
import { NextMaterialTable } from '../../../components/material table';
import LoaderSpinner from '../../../components/utils/loader-spinner';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


//convert class to function

function BillingPipelineTab({ billingPipeLineData }) {
  const { t } = useTranslation();
  const [isLoaded, set_isLoaded] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);

  function instructionToggle() {
    setInstructionModal((instructionToggle_prev) => {
      return !instructionToggle_prev;
    });
  }

  const columns = useMemo(() => [{
    header: t('program.manager.project.column.text'),
    id: 'projectName',
    accessorKey: 'projectName',
    size: 150,
    enableResizing: false,
    enableSorting: true,
    dataType: 'string',
    sortingFn: (rowA, rowB, columnId) => {
      const valueA = rowA.getValue(columnId) || '';
      const valueB = rowB.getValue(columnId) || '';
      return valueA.localeCompare(valueB, undefined, { sensitivity: 'base', numeric: true });
    },
    Cell: ({ row }) => {
      const rowData = row.original;
      return (
        <div className="text_ellipsis" title={rowData.projectName}>
          {!_.isEmpty(rowData.projectName) ? rowData.projectName : '-'}
        </div>
      );
    },
  },
    {
      header: t('mt.sales.pipeline.tab1.column.client.name.text'),
      id: 'clientName',
      size: 150,
      enableResizing: false,
      enableSorting: true,
      dataType: 'string',
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId) || '';
        const valueB = rowB.getValue(columnId) || '';
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base', numeric: true });
      },
      accessorFn: (row) => row.clientName,
      Cell: ({ row }) => {
        const rowData = row.original;
        return ((<div className="text_ellipsis" title={rowData.clientName}>
          {!_.isEmpty(rowData.clientName) ? rowData.clientName : '-'}
        </div>));
      },
    }, {
      header: t('mt.sales.pipeline.tab1.column.project.manager.text'),
      id: 'projectManagerName',
      size: 150,
      enableResizing: false,
      enableSorting: true,
      dataType: 'string',
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId) || '';
        const valueB = rowB.getValue(columnId) || '';
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base', numeric: true });
      },
      accessorFn: (row) => row.projectManagerName,
      Cell: ({ row }) => {
        const rowData = row.original;
        return ((<div className="text_ellipsis" title={rowData.projectManagerName}>
          {!_.isEmpty(rowData.projectManagerName) ? rowData.projectManagerName : '-'}
        </div>));
      },
    }, {
      header: t('mt.sales.pipeline.tab1.column.sales.member.text'),
      id: 'salesMemberName',
      accessorFn: (row) => row.salesMemberName,
      size: 150,
      enableResizing: false,
      enableSorting: true,
      dataType: 'string',
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId) || '';
        const valueB = rowB.getValue(columnId) || '';
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base', numeric: true });
      },
      Cell: ({ row }) => {
        const rowData = row.original;
        return ((<div className="text_ellipsis" title={rowData.salesMemberName}>
          {!_.isEmpty(rowData.salesMemberName) ? rowData.salesMemberName : '-'}
        </div>));
      },
    }, {
      header: t('mt.sales.pipeline.tab1.column.april.text'), id: 'april',
      accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value =
          currentMonth > 4 ||
          currentMonth === 1 ||
          currentMonth === 2 ||
          currentMonth === 3
            ? row.invoicedApr
            : row.projectionApr;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    }

    , {
      header: t('mt.sales.pipeline.tab1.column.may.text'), id: 'may', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 5 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedMay : row.projectionMay;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.june.text'), id: 'june', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 6 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedJun : row.projectionJun;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.july.text'), id: 'july', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 7 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedJul : row.projectionJul;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.august.text'), id: 'august', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 8 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedAug : row.projectionAug;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.september.text'), id: 'september', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 9 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedSep : row.projectionSep;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.october.text'),
      id: 'october',
      accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value =
          currentMonth > 10 || currentMonth === 1 || currentMonth === 2 || currentMonth === 3
            ? row.invoicedOct
            : row.projectionOct;
        const numericValue = parseFloat(value);
        return isNaN(numericValue) ? 0 : numericValue;
      },
      size: 100,
      enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value.toLocaleString('en-IN');
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.november.text'), id: 'november', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 11 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedNov : row.projectionNov;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    }, {
      header: t('mt.sales.pipeline.tab1.column.december.text'), id: 'december', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth > 12 || (currentMonth === 1 || currentMonth === 2 || currentMonth === 3)) ? row.invoicedDec : row.projectionDec;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    }, {
      header: t('mt.sales.pipeline.tab1.column.january.text'), id: 'january', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = (currentMonth === 2 || currentMonth === 3) ? row.invoicedJan : row.projectionJan;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    }, {
      header: t('mt.sales.pipeline.tab1.column.february.text'), id: 'february', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = currentMonth === 3 ? row.invoicedFeb : row.projectionFeb;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN'); // Format for display
      },
    },
    {
      header: t('mt.sales.pipeline.tab1.column.march.text'), id: 'march', accessorFn: (row) => {
        const currentMonth = new Date().getMonth() + 1;
        const value = row.projectionMar;
        return isNull(value) ? 0 : value;
      }, size: 100, enableResizing: false,
      dataType: 'number',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return isNull(value) ? '0' : value.toLocaleString('en-IN');
      },
    }]);


  useEffect(() => {
    async function fetchData() {

      set_isLoaded(true);
    }

    fetchData();
  }, []);

  if (!isLoaded) {


    return (<Card className="text-center">
      <CardBody>
        <LoaderSpinner />
      </CardBody>
    </Card>);
  } else {

    return (<>
      <div className="ms-2" style={{ fontSize: '13px' }}>
          <span style={{ color: '#333' }}>
    {t(`integr8.dashboard.all.the.data.represented.instruction`)}
            <br />
            {/*<i className="fa fa-info-circle" aria-hidden="true" style={{ color: '#007bff', marginRight: '8px' }}></i>*/}
            <a href="#" onClick={instructionToggle} style={{ textDecoration: 'underline', color: '#007bff' }}>
      {t(`integr8.dashboard.click.here.to.learn.instruction`)}
    </a>
  </span>
      </div>

      <div className="table_hover">
        <NextMaterialTable
          columns={columns}
          data={billingPipeLineData}
          options={{
            enableColumnResizing: true,
            initialState: { columnOrder: ['projectName', 'clientName', 'projectManagerName', 'salesMemberName', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december', 'january', 'february', 'march'] }, // enableRowSelection: true,
            // onRowSelectionChange: ({ rowSelectionState }) => console.log(rowSelectionState),
            state: {
              showLoadingOverlay: !isLoaded, showSkeletons: !isLoaded,
            },
          }}
        />
      </div>


      {/*==========================================Instruction Modal=================================================*/}

      <Modal
        isOpen={instructionModal}
        toggle={instructionToggle}
        className="modal-lg"
        backdrop={'static'}
      >
        <ModalHeader toggle={instructionToggle}>{t(`integr8.billing.pipeline.modal.heading`)}</ModalHeader>
        <ModalBody>
          <div>
            <div className={'instruction-body'}>
              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.billing.pipeline.modal.instruction.title1`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.billing.pipeline.modal.instruction.content1`)}</span>
              </section>

              <section className={'instruction-section'}>
                <h6 className={'instruction-title'}>{t(`integr8.billing.pipeline.modal.instruction.title2`)}</h6>
                <span
                  className={'instruction-content'}>&nbsp;{t(`integr8.billing.pipeline.modal.instruction.content2`)}</span>
              </section>

              <div style={{ color: 'red', textAlign: 'center', lineHeight: '1.5' }}>
                <span>&nbsp;{t(`integr8.billing.pipeline.modal.instruction.content3`)}</span>
              </div>
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <Button className="integr8_cancel_btn"
                  onClick={instructionToggle}>{t(`integr8.close.btn.text`)}</Button>

        </ModalFooter>

      </Modal>


    </>);
  }
  ;
}

export default BillingPipelineTab;
